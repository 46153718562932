const year = new Intl.DateTimeFormat(import.meta.env.VITE_LOCALE, {
  year: "numeric",
  timeZone: import.meta.env.VITE_TZ,
});
const month = new Intl.DateTimeFormat(import.meta.env.VITE_LOCALE, {
  month: "2-digit",
  timeZone: import.meta.env.VITE_TZ,
});

export function toYearMonthPair(date: Date) {
  return `${year.format(date)}/${month.format(date)}`;
}
